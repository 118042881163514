<template>
  <NuxtLink :to="to">
    <article>
      <EventCover
        :image="image"
        :image-ratio="imageRatio">
        <!-- on mobile, show fist category, otherwise all -->
        <PrimaryBadge
          v-if="startingAt && endedAt"
          :dark="false"
          class="font-bold"
          >{{
            Interval.fromDateTimes(startingAt, endedAt).toLocaleString({
              year: 'numeric',
              month: 'short',
              day: '2-digit'
            })
          }},
          {{
            DateTime.fromJSDate(startingAt).toLocaleString({
              hour: '2-digit'
            })
          }}</PrimaryBadge
        >
        <ClientOnly>
          <PrimaryBadge
            v-for="(category, index) in categories?.slice(
              0,
              useScreen().isMobile.value ? 1 : categories.length
            )"
            :key="index"
            :dark="false"
            >{{ category }}</PrimaryBadge
          >
        </ClientOnly>
      </EventCover>
      <div class="flex items-center gap-3.5 px-1.5 py-3">
        <div class="flex-grow">
          <div
            v-if="author"
            class="text-sm text-neutral-500">
            {{ $t('EventCard.by') }} {{ author }}
          </div>
          <h3 class="text-left font-bold text-neutral-400">
            {{ title }}
          </h3>
        </div>
        <SecondaryButton
          left-icon="arrow-drop-right-line"
          :to="to" />
      </div>
    </article>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { Interval, DateTime } from 'luxon'

withDefaults(
  defineProps<{
    image: Image
    categories?: string[]
    startingAt?: Date
    endedAt?: Date
    author?: string
    title: string
    to?: string
    imageRatio?: string
  }>(),
  {
    imageRatio: 'aspect-[4/3]'
  }
)

const $img = useImage()

// cover image loading state
const loaded = ref(false)
const cover = ref()
</script>
